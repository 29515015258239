<template>
  <ResponsiveModalNew :open="open" @onClose="closePinModal" :id="id">
    <template v-slot:modal-content>
      <d iv class="success-center">
        <div>
          <div class="success-center">
            <div class="handler_logo">
              <img src="@/assets/icons/success@2x.svg" alt="success_logo" />
            </div>

            <h2>{{ $t("Payment Successful") }}</h2>
            <p class="title mb-10 text-center">{{ $t("Success") }}</p>
            <div class="success_response">
              <p>
                {{
                  $t(
                    "Now you will be logged in on the TV automatically"
                  )
                }}
              </p>
            </div>
            <!-- <PinLayout
              :pinCount="pinCount"
              :value="enteredPin"
              @onChange="onPinChange"
              @onCompleted="onPinEntered"
            /> -->
          </div>
          <div v-if="formError" class="mb-10 form-error text-center">
            {{ formError }}
          </div>
          <div v-else class="mb-10">""</div>
          <!-- <div class="mb-10">
            <button @click="onSubmit" class="button-primary">Submit</button>
          </div> -->
        </div>
      </d>
    </template>
  </ResponsiveModalNew>
</template>

<script>
export default {
  components: {
    ResponsiveModalNew: () => import("@/components/utils/ResponsiveModalNew.vue"),
    // SuccessModal: () => import("@/components/utils/SuccessModal.vue"),
  },
  events: ["onClose", "onActionComplete"],
  props: ["open", "mode", "pinError", "id"],
  mounted() {
    this.computeHeadings();
  },
  watch: {
    pinError(error, oldError) {
      this.formError = error;
      setTimeout(() => {
        this.formError = "";
      }, 5000);
    },
    formError(error, oldError) {
      if (error === "") {
        return;
      }
      setTimeout(() => {
        this.formError = "";
      }, 5000);
    },
    mode(mode, oldMode) {
      this.computeHeadings();
    },
  },

  methods: {
    closePinModal() {
      // this.enteredPin = "";
      // this.formError = "";
      this.$emit("onClose");
this.$router.replace({ name: "Home", params: { lang: this.$i18n.locale == 'ara' ? 'ar' : 'en'}});
      //this.$router.push({ name: "Home" });
    },
    computeHeadings() {
      let headingsObj = {};
      if (this.mode === "new") {
        headingsObj.title = "Set Pin";
        headingsObj.body = "Enter Pin to watch restricted content";
      } else if (this.mode === "edit") {
        headingsObj.title = "Enter Pin";
        headingsObj.body = "Please enter your current Pin";
      } else {
        headingsObj.title = "Viewing restrictions enabled";
        headingsObj.body = "To watch the content please enter pin";
      }
      this.headings = headingsObj;
    },
    onPinEntered(pin) {
      this.enteredPin = pin;
    },
    onPinChange(pin) {
      this.enteredPin = pin;
    },
    onSubmit() {
      if (this.enteredPin === "" || this.enteredPin.length < this.pinCount) {
        this.formError = "Please enter a valid pin";
        return;
      }
      this.$emit("onActionComplete", this.enteredPin);
    },
  },
  // components: {
  //   ResponsiveModal: () => import("@/components/utils/ResponsiveModal.vue"),
  //   //PinLayout: () => import("@/components/utils/PinLayout.vue"),
  // },
  data() {
    return {
      enteredPin: "",
      pinCount: 4,
      headings: {
        title: "",
        body: "",
      },
      formError: "",
    };
  },
};
</script>

<style lang="scss">
@import "@/sass/_variables.scss";
@import "./SuccessModalNew.scss"

</style>